import PositionLevelChoose from '@/component/positionLevelChoose/index.vue';
import getTrainsPolicyDetail from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getTrainsPolicyDetail";
import addTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addTravelPolicy';
import queryBusinessOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryBusinessOverweightBecause';
import updatePolicyByBusinessType from "@/lib/data-service/haolv-default/consumer_web_policyConfig_updatePolicyByBusinessType";

export default {
    data() {
        return {
            id: 0,
            type: '',   //add 新增 edit 编辑
            loading: false,
            positionVoArr: [],
            trainsPolicyDetail: {
                businessType: 2,   //业务类型：1.机票，2.火车票，3.酒店，4.用车
                positionIds: [],   //职位id
                trainsPolicy: 1,   //火车政策限制:1.不限,2.指定坐席,3.不允许预定
                cGSeatCode: [],   //C/G坐席编码
                cGSeatName: '',   //C/G坐席名字
                dSeatCode: [],   //D-动车坐席编码
                dSeatName: '',   //D-动车坐席名字
                seatCode: [],   //普通坐席编码
                seatName: '',   //普通坐席名字
                trainsControlRules: 1   //火车管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
            },

            disabledBoolean: true,

            trainsPolicyList: [
                {
                    trainsPolicy: 1,
                    trainsPolicyText: "不限"
                },
                {
                    trainsPolicy: 2,
                    trainsPolicyText: "指定坐席"
                },
                {
                    trainsPolicy: 3,
                    trainsPolicyText: "不允许预定"
                }
            ],
            cGSeatList: [
                {
                    cGSeatCode: 'swz',
                    cGSeatName: "商务座"
                },
                {
                    cGSeatCode: 'tdz',
                    cGSeatName: "特等座"
                },
                {
                    cGSeatCode: 'ydz',
                    cGSeatName: "一等座"
                },
                {
                    cGSeatCode: 'edz',
                    cGSeatName: "二等座"
                },
                {
                    cGSeatCode: 'wz',
                    cGSeatName: "无座"
                }
            ],
            dSeatList: [
                {
                    dSeatCode: 'swz',
                    dSeatName: "商务座"
                },
                {
                    dSeatCode: 'tdz',
                    dSeatName: "特等座"
                },
                {
                    dSeatCode: 'gjdw',
                    dSeatName: "高级动卧"
                },
                {
                    dSeatCode: 'dw',
                    dSeatName: "动卧"
                },
                {
                    dSeatCode: 'ydz',
                    dSeatName: "一等座"
                },
                {
                    dSeatCode: 'edz',
                    dSeatName: "二等座"
                },
                {
                    dSeatCode: 'gjrw',
                    dSeatName: "高级软卧"
                },
                {
                    dSeatCode: 'rw',
                    dSeatName: "软卧"
                },
                {
                    dSeatCode: 'wz',
                    dSeatName: "无座"
                }
            ],
            seatList: [
                {
                    seatCode: 'rw',
                    seatName: "软卧"
                },
                {
                    seatCode: 'yw',
                    seatName: "硬卧"
                },
                {
                    seatCode: 'rz',
                    seatName: "软座"
                },
                {
                    seatCode: 'yz',
                    seatName: "硬座"
                },
                {
                    seatCode: 'wz',
                    seatName: "无座"
                }
            ],
            trainsControlRulesList: [
                {
                    trainsControlRules: 1,
                    trainsControlRulesText: "允许预定"
                },
                {
                    trainsControlRules: 2,
                    trainsControlRulesText: "超标审批"
                },
                {
                    trainsControlRules: 3,
                    trainsControlRulesText: "禁止预定"
                }
            ],

            tipsList: [],
            canSubmit: true
        }
    },
    components: {
        PositionLevelChoose
    },
    created() {},
    mounted() {},
    activated() {
        this.type = this.$route.query.type
        if (this.type === 'edit') {
            this.id = this.$route.query.id
            this.getTrainsPolicyDetail()
        }
        this.$refs.positionChoose.init({
            id: this.id,
            type: this.type,
            businessType: 2
        })
        this.queryBusinessOverweightBecause()
    },
    deactivated() {
        this.id = 0
        this.trainsPolicyDetail = {
            businessType: 2,   //业务类型：1.机票，2.火车票，3.酒店，4.用车
            positionIds: [],   //职位id
            trainsPolicy: 1,   //火车政策限制:1.不限,2.指定坐席,3.不允许预定
            cGSeatCode: [],   //C/G坐席编码
            cGSeatName: '',   //C/G坐席名字
            dSeatCode: [],   //D-动车坐席编码
            dSeatName: '',   //D-动车坐席名字
            seatCode: [],   //普通坐席编码
            seatName: '',   //普通坐席名字
            trainsControlRules: 1   //火车管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
        }
    },
    watch: {
        positionVoArr(newVal) {
            console.log('newVal-positionVoArr', newVal)
        },
        'trainsPolicyDetail.trainsPolicy'(val) {
            this.disabledBoolean = val===2 ? false : true
        }
    },
    computed: {},
    filters: {},
    methods: {
        async getTrainsPolicyDetail() {
            try {
                const res = await getTrainsPolicyDetail({ id: this.id })
                this.trainsPolicyDetail = {...this.trainsPolicyDetail, ...res.datas}
                this.trainsPolicyDetail.cGSeatCode = this.trainsPolicyDetail.cGSeatCode.split('，').filter(item=>item)
                this.trainsPolicyDetail.dSeatCode = this.trainsPolicyDetail.dSeatCode.split('，').filter(item=>item)
                this.trainsPolicyDetail.seatCode = this.trainsPolicyDetail.seatCode.split('，').filter(item=>item)
            } catch (error) {
                this.$message({
                    type: "error",
                    message: '获取失败'
                })
            }
        },
        async queryBusinessOverweightBecause() {
            try {
                this.loading = true
                const res = await queryBusinessOverweightBecause({ businessType: 2 })
                this.tipsList = res.datas
            } catch (error) {
                this.tipsList = []
            } finally {
                this.loading = false
            }
        },
        filterTrainDataByCode(trainData, filterDate, code, name) {
            console.log('1111', trainData, filterDate, code, name)
            return trainData.reduce((total, item) => {
                console.log(item)
                if (filterDate.indexOf(item[code]) > -1) {
                    return [...total, item[name]]
                }
                return total
            }, [])
            .join("，")
        },
        submitData() {

            this.trainsPolicyDetail.cGSeatName = this.filterTrainDataByCode(
                this.cGSeatList,
                this.trainsPolicyDetail.cGSeatCode,
                "cGSeatCode",
                "cGSeatName"
            )
            this.trainsPolicyDetail.cGSeatCode = this.trainsPolicyDetail.cGSeatCode.join('，')

            this.trainsPolicyDetail.dSeatName = this.filterTrainDataByCode(
                this.dSeatList,
                this.trainsPolicyDetail.dSeatCode,
                "dSeatCode",
                "dSeatName"
            )
            this.trainsPolicyDetail.dSeatCode = this.trainsPolicyDetail.dSeatCode.join('，')

            this.trainsPolicyDetail.seatName = this.filterTrainDataByCode(
                this.seatList,
                this.trainsPolicyDetail.seatCode,
                "seatCode",
                "seatName"
            )
            this.trainsPolicyDetail.seatCode = this.trainsPolicyDetail.seatCode.join('，')
        },
        async addTravelPolicy() {
            try {
                this.loading = true
                this.submitData()
                const res = await addTravelPolicy(this.trainsPolicyDetail)
                this.$message({
                    type: "success",
                    message: '新增成功'
                })
            } catch (error) {
                this.$message({
                    type: "error",
                    message: '新增失败'
                })
            } finally {
                this.canSubmit = true
                this.loading = false
                this.cancleEvectionTrainPolicy()
            }
        },
        async updatePolicyByBusinessType() {
            try {
                this.loading = true
                this.trainsPolicyDetail.id = this.id
                this.submitData()
                const res = await updatePolicyByBusinessType(this.trainsPolicyDetail)
                this.$message({
                    type: "success",
                    message: '更新成功'
                })
            } catch (error) {
                this.$message({
                    type: "error",
                    message: '更新失败'
                })
            } finally {
                this.canSubmit = true
                this.loading = false
                this.cancleEvectionTrainPolicy()
            }
        },
        cancleEvectionTrainPolicy(){
            this.$router.go(-1)
        },
        submitEvectionTrainPolicy() {
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            let positionVoId = []
            this.positionVoArr.forEach(value => {
                if (value.isEcho) {
                    positionVoId.push(value.id)
                }
            })
            if (positionVoId.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请选择适用职级'
                })
                this.canSubmit = true
                return
            }
            this.trainsPolicyDetail.positionIds = positionVoId
            if (this.trainsPolicyDetail.trainsPolicy === 2) {
                if (!this.trainsPolicyDetail.cGSeatCode.length || !this.trainsPolicyDetail.dSeatCode.length || !this.trainsPolicyDetail.seatCode.length) {
                    this.$message({
                        type: "warning",
                        message: '请选择坐席'
                    })
                    this.canSubmit = true
                    return
                }
            } else {
                this.trainsPolicyDetail = {
                    businessType: 2,   //业务类型：1.机票，2.火车票，3.酒店，4.用车
                    positionIds: this.trainsPolicyDetail.positionIds,   //职位id
                    trainsPolicy: this.trainsPolicyDetail.trainsPolicy,   //火车政策限制:1.不限,2.指定坐席,3.不允许预定
                    cGSeatCode: [],   //C/G坐席编码
                    cGSeatName: '',   //C/G坐席名字
                    dSeatCode: [],   //D-动车坐席编码
                    dSeatName: '',   //D-动车坐席名字
                    seatCode: [],   //普通坐席编码
                    seatName: '',   //普通坐席名字
                    trainsControlRules: 1   //火车管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                }
            }
            if (this.type === 'add') {
                this.addTravelPolicy()
            } else {
                this.updatePolicyByBusinessType()
            }
        }
    }
}
